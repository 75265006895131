$gold: #967F4A;

.drilldown .is-drilldown-submenu-parent>a::after {
	border: none;
}

[class*='icon-']:before, .drilldown .is-drilldown-submenu-parent>a::after {
	color:white;
	font-size: 30px;
	width: initial;
	height: initial;
	display: inline-block;
	font-family: 'Glyphter';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.drilldown .is-drilldown-submenu-parent>a::after, .icon-Mobile-Nav-Icon-Arrow:before{content:'\0041'; font-size: 12px; margin-right: 5px;}
.icon-Mobile-Nav-Icon-Back:before{content:'\0042';}
.icon-Mobile-Nav-Icon-Close:before{content:'\0043';}
.icon-Mobile-Nav-Icon-Search:before{content:'\0044';}

.icon-Mobile-Nav-Icon-Back , .icon-Mobile-Nav-Icon-Close {
	display: flex;
}

#mobile_menu {
	display: none;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	background-color: $gold;
	z-index: 1000;
	font-family: 'Source Sans Pro', sans-serif;
	overflow: scroll;

	a {
		&:hover, &:focus {
			text-decoration: none;
		}

	}

	.controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 17px;

		.btn {
			padding: 0;

			a {
				margin: 0;
			}
		}

		.become-an-owner {
			display: flex;
			align-items: center;

			a {
				border-radius: 5px;
				padding: 5.5px 14.5px;
				background-color: white;
				color: $gold;
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
		.menu-wrapper {
			//overflow: hidden;
		}

		ul {
			padding: 0;
			margin: 0;
			background-color: $gold;

			li {
				width: 100%;

				a {
					padding: 14px 20px;
					display: block;
					width: 100%;
					background-color: $gold;
					color: white;
					font-size: 20px;
					border-top: 1px solid white;
				}
				&:last-child {
					a {
						border-bottom: 1px solid white;
					}
				}
			}
		}

		.dropdown {
			position: static !important;
		}

		.is-drilldown, .drilldown .drilldown-submenu-cover-previous {
			max-width: 100% !important;
			min-height: 0 !important;
		}

		.search {
			margin: 20px;
			position: relative;

			form {
				width: 100%;
			}

			label {
				display: none;
			}

			[type="text" ] {
				height: 40px;
				padding: 20px 13px;
				font-size: 16px;
				width: 100%;
				font-style: italic;
			}

			[type="submit"] {
				display: none;
			}

			.icon-Mobile-Nav-Icon-Search {
				position: absolute;
				top: 0;
				right: 0;
				margin: auto;
				height: 100%;
				display: flex;
				align-items: center;
				padding: 14px;
			}

			.icon-Mobile-Nav-Icon-Search:before {
				font-size: 20px;
				color: $gold;
				margin-top: -3px;
			}

		}

		.js-drilldown-back {
			display: none;
		}

		.icon-Mobile-Nav-Icon-Back {
			visibility: hidden;
			pointer-events: none;
			&.reveal {
				visibility: visible;
				pointer-events: initial;
			}
		}

		&.open_menu {
			display: block;
		}
}
