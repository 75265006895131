@import '~foundation-sites/scss/foundation';
@import '~foundation-sites/scss/util/util';
// need to add settings file if you are using a custom foundation configuration.
@import 'vendors/foundation/settings';
@import "~@fortawesome/fontawesome-free/css/all.css";
@include foundation-drilldown-menu;

/* Generated by Glyphter (http://www.glyphter.com) on  Wed Feb 12 2020*/
@font-face {
	font-family: 'Glyphter';
	src: url('../fonts/Glyphter.eot');
	src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Glyphter.woff') format('woff'),
	url('../fonts/Glyphter.ttf') format('truetype'),
	url('../fonts/Glyphter.svg#Glyphter') format('svg');
	font-weight: normal;
	font-style: normal;
}

.overflow-hidden {
	overflow: hidden !important;
}

// Import scss and css here
@import "../css/bootstrap.css";
@import "../css/jquery.dataTables.css";
@import "../css/site.css";
@import "../css/style.css";

@import "component/drilldown";
