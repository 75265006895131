/*
Theme Name: Kenwood
*/
html, div, body, iframe, audio, video { margin: 0; padding: 0; border: 0; outline: 0; }
*:focus { outline:none !important; border: none; }

input[type="text"]:focus { outline:none; }
html, body { height: 100%; width: 100%; font-size: 14px;line-height: 20px; font-family: 'Lora', serif;font-weight: 400; color: #000000; }
p { font-size: 14px; line-height: 25px; margin-bottom: 15px; }
img {max-width: 100%; }

/*  common class */
.p0 { padding: 0px !important; }
.top0 { padding-top: 0px !important; }
.bottom0 { padding-bottom: 0 !important; }
.left0 { padding-left: 0px !important; }
.right0 { padding-right: 0px !important; }
.top10 { padding-top: 10px; }
.top20 { padding-top: 20px; }
.top30 { padding-top: 30px; }
.top40 { padding-top: 40px; }
.bottom20 { padding-bottom: 20px; }
.bottom30 { padding-bottom: 30px; }
.bottom40 { padding-bottom: 40px; }
.p10 { padding-top: 10px; padding-bottom: 10px; }
.p20 { padding-top: 20px; padding-bottom: 20px; }
.p30 { padding-top: 30px; padding-bottom: 30px; }
.p40 { padding-top: 40px; padding-bottom: 40px; }

.clear { clear: both; visibility: hidden; line-height: 0; height: 0; }
.clear20 { clear: both; visibility: hidden; line-height: 0; height: 20px; }
.clear30 { clear: both; visibility: hidden; line-height: 0; height: 30px; }

.alignleft {max-width: 100%; float: left; margin: 0 20px 20px 0; }
.alignright {max-width: 100%; float: right; margin: 0 0 20px 20px; }
.aligncenter {max-width: 100%;display: block; margin: 10px auto; }
.transition {-webkit-transition: all 600ms ease-out; -moz-transition: all 600ms ease-out;-o-transition: all 600ms ease-out;transition: all 600ms ease-out;}
.tableDiv { display: table; width: 100%;height: 100%; }
.table_cell {display: table-cell; vertical-align: middle; }
#wrapper { min-height:100%; position:relative; }
#inner_wrapper { height:auto; width: 100%;}
.contentDiv { position: relative; width: 100%; }
.flex-row {display: flex;}

h1, h2, h3, h4, h5, h6 {font-family: 'Lora', serif;margin-top: 0;}
h1 {}
h2 {font-size: 40px; line-height: 44px; color: #af965a; margin-bottom: 15px;font-weight: 400;}
h3 {}
h4 {font-size: 24px; line-height: 28px;margin-bottom: 15px;font-weight: 700;}

/* header top */
.header_top {background: #967f4a; }
.header_top ul, .heder_bottom ul {list-style: none; margin: 0;padding: 0; }
.header_top ul li, .heder_bottom ul li{float: left;display: block; }
.header_top ul li a { padding: 11px 10px 9px 9px; display: block; font-size: 16px; line-height: 20px; color: #ffffff; }
.header_top ul li.active a { background: #af965a; }
.header_top ul li a:hover {text-decoration: none; }

i.trophy {margin-right: 8px;background: url(../img/social_icon.png) no-repeat; background-position: 0 0; display: inline-block;float: left; width: 18px; height: 18px;}
i.fb {margin-right: 8px;background: url(../img/social_icon.png) no-repeat; background-position: -51px 0; display: inline-block;float: left; width: 18px; height: 18px;}
i.vimeo {margin-right: 8px;background: url(../img/social_icon.png) no-repeat; background-position: -113px 0px; display: inline-block;float: left; width: 18px; height: 18px;}
i.linkedin {margin-right: 8px;background: url(../img/linkedin-icon.png) no-repeat; display: inline-block;float: left; width: 18px; height: 18px;}
i.twitter {margin-right: 8px;background: url(../img/Kenwood-Twitter-Wht.png) no-repeat; display: inline-block;float: left; width: 18px; height: 18px;}
i.instagram {margin-right: 8px;background: url(../img/instagram-icon.png) no-repeat; display: inline-block;float: left; width: 18px; height: 18px;}
i.owner {margin-right: 8px;background: url(../img/social_icon.png) no-repeat; background-position: -175px 0px; display: inline-block;float: left; width: 18px; height: 18px;}

/* heder bottom */
.heder_bottom {background: #000000; }
.heder_bottom ul li {margin-left: 38px; padding: 36px 0 38px; position: relative;}
.heder_bottom ul li:first-child { margin-left: 0px;}
.heder_bottom ul li a {padding: 0px; display: block; font-family: 'Source Sans Pro', sans-serif;font-size: 22px; font-weight: 600; line-height: 26px;color: #ffffff;-webkit-transition: all 600ms ease-out; -moz-transition: all 600ms ease-out;-o-transition: all 600ms ease-out;transition: all 600ms ease-out; }
.heder_bottom ul li ul.sub_menu li {background: none;}
.heder_bottom ul li ul.sub_menu li:hover a {text-decoration: none; color: #ea91bd !important; }
.heder_bottom ul li ul.sub_menu li.active_menu a {text-decoration: none; color: #ea91bd !important; }
.heder_bottom ul li:hover, .heder_bottom ul li.active_menu {background: url(../img/menu_hover.png) no-repeat; background-position: 50% 70px; }
.heder_bottom ul li:hover a, .heder_bottom ul li.active_menu a, .heder_bottom ul li ul.sub_menu li:hover a {text-decoration: none; color: #ea91bd; }
.heder_bottom ul li:hover ul.sub_menu { display: block;}
.heder_bottom ul li ul.sub_menu {display: none;position: absolute;z-index: 9;background: #000000;top: 100%;padding: 10px 15px;}
.heder_bottom ul li ul.sub_menu li { padding: 5px 0;margin: 0; float: none; }
.heder_bottom ul li ul.sub_menu li a {white-space: nowrap; color: #ffffff; font-size: 18px; }
/* logo */
.logo_div { width: 240px; position: absolute; left: 0; right: 0;margin: 0 auto; z-index: 9;}

/* banner */
.banner_left_img {position: absolute;left: 0;z-index: 1;width: 500px; height: 500px;background: url(../img/left_img.png) no-repeat;background-repeat: no-repeat;background-size: cover;}
.banner_right_img {position: absolute; right: 0;z-index: 1; width: 708px; height: 500px; background: url(../img/right_img.png) no-repeat;background-repeat: no-repeat;background-size: cover;}
.banner {position: relative; height: 500px; background: url(../img/banner_img.jpg) no-repeat;background-repeat: repeat;}
.banner h1 {font-size: 60px; color: #ffffff; line-height: 65px; font-weight: normal;text-align: center; margin-bottom: 20px;}
.banner p {font-size: 20px; color: #ffffff; line-height: 24px; font-style: italic; font-weight: normal;text-align: center; margin: 0 auto 20px;}
.banner .row > div {padding: 0;}
.banner .row .text-center {margin-top: 35px;}
.center_img {width: 14px; height: 21px; background:url(../img/pink_img.png) no-repeat 50% 50%; }
.g_border {width: 100%; max-width: 450px; text-align: center; margin: 0 auto 20px; position: relative;}
.g_border span {  display: inline-block;  position: relative;  }
.g_border span:before, .g_border span:after {content: "";position: absolute;height: 1px;border-bottom: 1px solid #28ad81;top: 49%;width: 208px;}
.g_border span:before {  right: 100%;  margin-right: 10px;}
.g_border span:after {  left: 100%;  margin-left: 10px;}
.d_button{ padding: 9px 20px;display: inline-block; background: #af965a; font-size: 18px; line-height: 25px;font-weight: 700; color: #ffffff; text-align: center; border-radius: 3px; -moz-border-radius: 3px; -webkit-border-radius: 3px;}
.d_button:hover, a.d_button:focus  {color: #fff; text-decoration: none;background: #967f4a; }
.bottom_img {text-align: center; margin: 20px 0 0;}
.dot_img {width: 14px; height: 21px;margin-left: -4px;display: inline-block; background:url(../img/pink_img.png) no-repeat 50% 50%;}
.banner_content {z-index: 2; }
/* mid sec */
.mid_sec {padding: 60px 0; }
.mid_sec p {font-size: 15px;}
.mid_sec .imgBox img {max-width: none;}
.mid_sec .right_content {padding-left: 20px;}
.mid_sec .right_content h4 {margin-bottom: 0; color: #af965a; }
.mid_sec .right_content h4 a {color: #af965a; }
.mid_sec .right_content h4 a:hover {color: #967f4a; text-decoration: none;}
.mid_sec .right_content p {margin-bottom: 0; }

/* news box */
.news_sec {background: #daf2ea; padding:60px 0; }
.news_sec .newsTitle {position: relative;}
.news_sec .newsTitle h2 {margin-bottom: 60px; color: #006330;}
.news_sec .newsBox {margin-bottom: 60px; }
.newsBox img {margin-bottom: 18px;}
.newsBox h4 {font-weight: 600;line-height: 26px; }
.newsBox .news_date {display: block; font-size: 15px; line-height: 16px; color: #af965a; }
.newsBox hr {margin-top: 10px; margin-top: 10px; border-top: 1px solid #bce3ce;}
.newsBox .news_btn {display: inline-block;color: #ffffff;padding: 2px 10px 1px;font-size: 12px;text-transform: uppercase;line-height: 20px;-webkit-border-radius: 3px;-moz-border-radius: 3px;border-radius: 3px;background: #0e764a;}
.newsBox .news_btn:hover {background: #0f8d63;text-decoration: none;}
.newsBox .news_link {color: #000000;}
.newsBox .news_link:hover {color: #0f8d63; text-decoration: none;}
.view_all_news {margin-left: auto; margin-right: auto;background: #af965a;color: #ffffff;padding: 15px 20px;font-size: 18px;text-transform: uppercase;line-height: 20px;border-radius: 3px;-webkit-border-radius: 3px;-moz-border-radius: 3px;}
.view_all_news:hover {text-decoration: none; color: #ffffff;background: #967f4a; }

/* bottom sec */
.bottom_sec {padding: 60px 0; }
.bottom_sec h2 {margin-bottom: 45px}
.bottom_sec h2, h3 {color: #006330;}
.bottom_sec h4 {}
.bottom_sec .container > div:last-child { margin-top: 20px; }
.bottom_sec .row {margin: 0;}
.bottom_sec .cont_div {margin-bottom: 25px;}
.bottom_sec .vider_div {display: grid; padding: 0 15px 0 40px; float: right;}
.bottom_sec .vider_div h3 {margin-bottom: 40px;}
.bottom_sec .d_button {margin-left: auto; margin-right: auto; padding: 20px 16px;font-size: 18px;font-weight: 400;line-height: 6px;}

/* footer */
.footer {position: absolute; bottom: 0; left: 0; right: 0;}
.inner_footer {border-top: 5px solid #eea5c9; background: #000000; padding: 60px; font-size: 12px; line-height: 16px;color: #ffffff;}
.copyright_col, .menu_col, .address_col {float: left;}
.copyright_col {width: 17.7%;}
.menu_col {width: 12.6%;margin-top: -2px;}
.address_col {width: 20%;margin-top: -3px;}
.inner_footer .menu_col ul {list-style: none; padding: 0; margin: 0;}
.inner_footer .menu_col ul li {padding-left: 6px; display: block; background: url(../img/foore_menu_dot.png) no-repeat 0 50%; }
.inner_footer .menu_col ul li a {color: #ffffff; font-size: 12px; line-height: 16px;}
.footer_logo {margin-bottom: 16px; }
.address_col  address a {color: #fff;}
.footer_social ul {list-style: none; padding: 0; margin: 0;}
.footer_social ul li {float: left;margin-left: 20px;}
.footer_social ul li a {font-size: 13px;color: #ffffff; display: block; line-height: 18px;}
i.blog {margin-right: 8px;background: url(../img/social_icon.png) no-repeat;background-position: -227px 0;display: inline-block;float: left;width: 18px;height: 18px;}
.footer_social {float: right;}
.inner_footer address {margin-bottom: 0;}

/* mobile menu */
.mobile_menu_btn {display: none; width: 38px; height: 28px; float: right;}
.mobile_menu {position: fixed;overflow-y: auto;width: 300px;background: #ffffff;z-index: 99;right: -300px;bottom: 0;top: 0;}
.overlay {background: #000000; opacity: 0.7; left: 0;top:0; bottom: 0;right: 0; position: absolute; z-index: 1;display: none;}
.close_btn {padding: 35px 0 25px 0;}
.close_btn a {display: block;width: 38px;height: 36px;float: right;margin-right: 40px;position: relative;}
.mobile_menu .nav { list-style: none; padding: 0; margin: 0;}
.mobile_menu .nav li {display: block; margin: 0 40px; }
.mobile_menu .nav li a {position: relative; margin: 0; padding: 4px 20px 5px 20px; font-family: 'Source Sans Pro', sans-serif;font-size: 22px; font-weight: 600; line-height: 26px; color: #967f4a; display: block; }
.mobile_menu .nav li.active a {background: #af965e; }
/*.mobile_menu .nav li a:hover {text-decoration: none; color: #ffffff; background-color: url(../img/menu_hover.png) no-repeat; background-position: 0 50%;  }*/
.mobile_menu .nav li.active a {text-decoration: none; color: #e892bd; background: url(../img/menu_hover.png) no-repeat; background-position: 0 50%;  }
.mobile_menu .nav li a .caret {position: absolute;top: 50%;right: 0;}
.mobile_menu .nav .open > a, .mobile_menu .nav .open > a:hover, .mobile_menu .nav .open > a:focus, .mobile_menu .nav > li > a:hover, .mobile_menu .nav > li > a:focus, .mobile_menu .dropdown-menu > li > a:hover, .mobile_menu .dropdown-menu > li > a:focus { background-color: transparent; }
.mobile_menu .nav .dropdown-menu {position: relative; padding: 5px 0; background-color: transparent; border: none;border-radius: 0px;-webkit-box-shadow: none;box-shadow: none;margin: 0 -40px 0 20px;float: none;}
.mobile_menu .nav .dropdown-menu li:first-child {border-top: 1px solid #c0b268;}
.mobile_menu .nav .dropdown-menu li {margin: 0;border-bottom: 1px solid #c0b268;}
.mobile_menu .nav .dropdown-menu li a { font-family: 'Lora', serif;font-weight: 400;font-size: 16px;line-height: 21px;padding: 12px 10px;background-image: none;color: #967f4a;}
.mobile_menu .nav .dropdown-menu li.active a, .mobile_menu .nav .dropdown-menu li a:hover {color: #967f4a;}
.mobile_menu .social ul { list-style: none; padding: 0; margin: 30px 0 0;}
.mobile_menu .social ul li {display: block; margin: 10px 0; }
.mobile_menu .social ul li a {padding: 5px 40px 5px 40px; color: #967f4a; font-size: 16px; line-height: 20px;display: block; }
.mobile_menu .social ul li.active a {padding: 11px 40px 10px 40px; background: #af965e;color: #ffffff; }
.mobile_menu .social ul li a:hover {text-decoration: none; }
i.cross {display: block;width: 38px;height: 36px;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;
         user-select: none;-webkit-transition: background-color 0.3s;transition: background-color 0.3s;}
i.cross:before {-webkit-transform: translateY(0) rotate(45deg);transform: translateY(0) rotate(45deg);}
i.cross:after, i.cross:before {position: absolute;left: -2px;width: 44px;height: 7px;content: '';-webkit-transition: -webkit-transform 0.3s;transition: transform 0.3s;background: #ae955e;top: 14px;}
i.cross:after {-webkit-transform: translateY(0) rotate(-45deg);transform: translateY(0) rotate(-45deg);}
.mobile_menu i.fb {background: url(../img/social_icon.png) no-repeat;background-position: -320px 0;}
.mobile_menu i.vimeo {background: url(../img/social_icon.png) no-repeat;background-position: -274px 0px;}
.mobile_menu i.linkedin {background: url(../img/linkedin-icon-mobile.png) no-repeat;}
.mobile_menu i.twitter {background: url(../img/Kenwood-Twitter-gold.png) no-repeat;}
.mobile_menu i.instagram {background: url(../img/instagram-icon-mobile.png) no-repeat;}

/* off_canvas_nav */
.mobile_menu.open_menu {
    opacity: 1;
    right: 0;
    -webkit-animation: slideIn 0.35s ease-in-out;
    -moz-animation: slideIn 0.35s ease-in-out;
    -o-animation: slideIn 0.35s ease-in-out;
    animation: slideIn 0.35s ease-in-out; }

@-webkit-keyframes slideIn {
    0% {
        opacity: 1;
        right: -300px; }

    100% {
        opacity: 1;
        right: 0; } }

@-moz-keyframes slideIn {
    0% {
        opacity: 1;
        right: -300px; }

    100% {
        opacity: 1;
        right: 0; } }

@-o-keyframes slideIn {
    0% {
        opacity: 1;
        right: -300px; }

    100% {
        opacity: 1;
        right: 0; } }

@keyframes slideIn {
    0% {
        opacity: 1;
        right: -300px; }

    100% {
        opacity: 1;
        right: 0; } }

/*Nav Shrinking Closed Effect*/
.mobile_menu.close_menu {
    opacity: 1;
    right: -300px;
    -webkit-animation: slideOut 0.35s ease-in-out;
    -moz-animation: slideOut 0.35s ease-in-out;
    -o-animation: slideOut 0.35s ease-in-out;
    animation: slideOut 0.35s ease-in-out; }

@-webkit-keyframes slideOut {
    0% {
        opacity: 1;
        right: 0; }

    100% {
        opacity: 1;
        right: -300px; } }

@-moz-keyframes slideOut {
    0% {
        opacity: 1;
        right: 0; }

    100% {
        opacity: 1;
        right: -300px; } }

@-o-keyframes slideOut {
    0% {
        opacity: 1;
        right: 0; }

    100% {
        opacity: 1;
        right: -300px; } }

@keyframes slideOut {
    0% {
        opacity: 1;
        right: 0; }

    100% {
        opacity: 1;
        right: -300px; } }

/*  fuego-mi-amor  */
.bg {background: url('../img/bg_img.jpg') no-repeat; background-size: cover;background-position: 50% 50%;}
.horse_bg {position: absolute; z-index: 0; width: 100%;}
.horse_bg img {max-width: 100%;margin: auto;display: block;}
.left_side {padding-top: 40px;width: 16.7%;float: left;}
.right_side {float: left;width: 83.3%;}
.contentBox {position: relative; z-index: 1;}
.white_bg {padding: 62px 30px 30px; background: #ffffff; }
.left_side ul {margin: 0;list-style: none; background: #96804a; padding: 30px 0;}
.left_side ul li:first-child {border-top: 1px solid #c0b368; }
.left_side ul li {padding: 0 30px; display: block; border-bottom: 1px solid #c0b368; }
.left_side ul li a { padding: 16px 0; display: block; font-size: 16px; line-height: 20px; color: #ffffff;-webkit-transition: all 600ms ease-out; -moz-transition: all 600ms ease-out;-o-transition: all 600ms ease-out;transition: all 600ms ease-out;}
.left_side ul li a:hover { color: #e892bd; text-decoration: none; }
.left_side ul li a:focus { text-decoration: none;}
.left_side ul li.li_subchild a .caret { background: url('../img/menu_li_bg.png') no-repeat 0 0; width: 6px; height: 13px; border: none;margin-left: 4px;}
.left_side ul li.li_subchild.open a .caret {background: url('../img/open_li_bg.png') no-repeat 0 3px; width: 13px; height: 10px; }
.left_side ul li.li_subchild li.li_subchild a .caret { background: url('../img/menu_li_bg.png') no-repeat 0 0; width: 6px; height: 13px; border: none;margin-left: 4px;}
.left_side ul li.li_subchild.open li.li_subchild.open a .caret {background: url('../img/open_li_bg.png') no-repeat 0 3px;width: 13px; height: 10px; }
.left_side ul li.li_subchild ul.sec_lev_menu { display: none; background: #81673b; position: relative;float: none;border-radius: 0;box-shadow: none;border: none;padding: 0;margin: 0 -30px;}

.left_side ul li.li_subchild ul.sec_lev_menu li:first-child { border-top: 1px solid #b39053; }
.left_side ul li.li_subchild.open { border-bottom: 0px solid #b39053; }
.left_side ul li.li_subchild ul.sec_lev_menu li {border-bottom: 1px solid #b39053; }
.left_side ul li.li_subchild ul.sec_lev_menu > li > a:hover {background-color: transparent; text-decoration: none; color: #e892bd;}
.left_side ul li.current_page {background: url('../img/menu_li_bg.png') no-repeat 90% 50%;}
.page_title {margin-bottom: 37px;}
.page_title .heading {display: inline-block; padding: 0 0 25px 0; color: #ae955e; font-size: 35px; line-height: 40px; background: url('../img/title_bg.png') no-repeat 50% 100%; }
.img_border {border-bottom: 10px solid #ae955e; }
table tbody tr:nth-child(odd) {background: #e3e3e3;}
table.table_list {font-family: 'Source Sans Pro', sans-serif; font-size: 16px; font-weight: 600; line-height: 20px; color: #000000; }
table.table_list tr td {padding: 15px 15px;}
table.table_list tr td:first-child {padding-left: 30px; color: #666666; width: 110px; }
.left_box {padding-bottom: 10px;margin-bottom: 30px; border-bottom: 1px solid #ae955e;}
a.green_btn {background: #0e764a;font-size: 16px;line-height: 20px;display: inline-block;color: #ffffff;padding: 6px 10px;border-radius: 3px;margin-right: 20px; margin-bottom: 20px;}
a.green_btn:hover {background: #0f8d63; text-decoration: none;}
.entrie_bottom_text, .entrie_bottom_text p {font-family: 'Source Sans Pro', sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;}
.bottom_btn a.green_btn:last-child {margin-right: 0;}

/* table css */
table.dataTable#entrie_table {font-family: 'Source Sans Pro', sans-serif; font-size: 16px; font-weight: 400; line-height: 20px; color: #000000; }
table.dataTable#entrie_table.no-footer {  border-bottom: 0px solid #111111;}
table.dataTable#entrie_table thead th, table.dataTable#entrie_table thead td {text-align: center; font-weight: 600; color: #ffffff; padding: 5px 10px;border-bottom: 0px solid #111111; background-color: #08563c;border-bottom: 5px solid #ffffff; }
#entrie_table_wrapper .dataTables_info {display: none; }
table.dataTable#entrie_table.stripe tbody tr.odd, table.dataTable#entrie_table.display tbody tr.odd, table.dataTable#entrie_table.display tbody tr.odd > .sorting_1, table.dataTable#entrie_table.order-column.stripe tbody tr.odd > .sorting_1 {background-color: #e3e3e3;}
table.dataTable#entrie_table tbody th, table.dataTable#entrie_table tbody td {padding: 15px 10px;text-align: center;}
table.dataTable#entrie_table tbody th a, table.dataTable#entrie_table tbody td a {color: #0e764a; }
table.dataTable#entrie_table.display tbody tr.even > .sorting_1, table.dataTable#entrie_table.order-column.stripe tbody tr.even > .sorting_1 {background-color: #ffffff; }
table.dataTable#entrie_table tbody tr.even:hover, table.dataTable#entritable.dataTable#entrie_table.display tbody tr.even:hover {background-color: transparent;}


/* blog */
.fullwidth-banner {width: 100%; height: 200px; background: url('../img/full_banner_img.jpg') no-repeat; background-size: cover; background-position: 50% 50%; }
.blogBox {padding-bottom: 40px; margin-bottom: 37px;border-bottom:  1px solid #af955e; }
.blogBox .user_img {margin-top: 3px;float: left; width: 70px;}
.blogBox .user_img img {width: 70px; height: 70px; border: 5px solid #eeeeee; }
.blogBox .blog_text {padding-left: 90px;}
.blogBox .blog_text h4 {font-size: 25px;line-height: 28px;margin-bottom: 7px;font-weight: 400; color: #af955e;}
.blogBox .blog_text h4 a {color: #af955e;}
.blogBox .blog_text h4 a:hover {color: #967f4a; text-decoration: none;}
.blogBox .post_detail {margin-bottom: 4px;font-size: 12px; line-height: 16px;font-weight: 700;}
.blogBox .post_detail a.u_name {color: #17754b;}
.blogBox .read_more_btn {padding:4px 10px 3px; color: #ffffff;background: #17754b; font-size: 12px;text-transform: uppercase; line-height: 16px; display: inline-block;border-radius: 3px;-webkit-border-radius: 3px;-moz-border-radius: 3px; }
.blogBox .read_more_btn:hover {color: #ffffff; text-decoration: none; background: #0f8d63; }
.blog_page .page_title { margin-top: 33px; margin-bottom: 33px; }
.blog_page .blog_img {margin-top: 37px;}
.blogBox .blog_text p {margin-bottom: 12px;}
.blog_cont_div .blogBox:last-child {margin-bottom: 29px; }
.pogination_div {padding: 0 0 24px; text-align: center; }
ul.pogination { list-style: none; display: inline-block; margin: 0; padding: 0;}
ul.pogination li { margin: 0 5px 10px; display: inline-block;float: left; }
ul.pogination li a {width: 28px;height: 40px;display: block;background: url('../img/pagination_bg.png') no-repeat 0 0;font-size: 14px;line-height: 20px;color: #ffffff;padding: 11px 2px 9px; }
.next_arrow {margin: 5px auto; display: block; width: 0;height: 0;border-top: 5px solid transparent;border-bottom: 5px solid transparent;border-left: 5px solid #ffffff;}
ul.pogination li a:hover {text-decoration: none; background: url('../img/pagination_bg.png') no-repeat 100% 0; }




/* right sidebar */
.right_sidebar {margin-left: 10px;padding: 17px 20px 20px; background: #eeeeee; }
.news_letter .title .text {display: inline-block;padding: 0 0 14px 0;color: #ae955e;font-size: 25px;line-height: 28px;background: url('../img/news_title_bg.png') no-repeat 50% 100%;}
.news_letter .title {margin-bottom: 14px;}
.news_letter .newsletter_cont {font-size: 12px;line-height: 20px;margin-bottom: 14px;}
.newsletter_form form { margin: 0; }
.newsletter_form form input[type="text"], .newsletter_form form input[type="email"]  {width: 100%;border-radius: 3px;border: 1px solid #af965a;font-size: 12px;line-height: 16px;
                                                                                      color: #000000;padding: 4px 10px 3px;margin-bottom: 20px;}
.newsletter_form form input[type="submit"] {background: #af965a;border: none;border-radius: 3px;color: #ffffff;font-size: 10px;text-transform: uppercase;line-height: 18px;padding: 2px 10px 1px;-webkit-transition: all 600ms ease-out;-moz-transition: all 600ms ease-out;-o-transition: all 600ms ease-out;transition: all 600ms ease-out;}
.newsletter_form form input[type="submit"]:hover {background: #967f4a;}

/* entries */
.tablet_entrie {display: none;}
.tablet_entrie .title_text {padding-left: 15px;font-weight: 600;margin-bottom: 20px; font-size: 16px; line-height: 20px; color: #ae955e; font-family: 'Source Sans Pro', sans-serif;}
.tablet_entrie table.table_list tr td:first-child {padding-left: 15px;width: auto;}
.tablet_entrie .colBox {padding-bottom: 30px;}
.tablet_entrie .row_box {margin-bottom: 30px; border-bottom: 1px solid #ae955e; }
.tablet_entrie .row_box:last-child {margin-bottom: 0px; border-bottom: 0px solid #ae955e; }

/*  media query  */
@media (min-width: 768px) {
    .bottom_sec .col-md-5-1 {width: 44%;}
    .bottom_sec .col-md-7-1 {width: 56%;}
    .heder_bottom ul li.remove_border a { color: #ffffff !important; }
    .heder_bottom ul li.remove_border { background: none !important; }
}
@media (min-width: 992px) {
    .fuego_page .col-md-4-1 {width: 39.8%; }
    .fuego_page .col-md-8-1 {width: 60.2%; }
    .blog_page .col-md-9-1 {width: 76.4%; }
    .blog_page .col-md-3-1 {width: 23.6%; }
    .blogBox .col-md-8-1-1 {width: 73.4%; }
    .blogBox .col-md-4-1-1 {width: 26.6%; }

}

@media (min-width: 1300px) {
    #wrapper .container {width: 1230px;}
    .news_sec .row {margin-left: -20px; margin-right: -20px;}
    .news_sec .col-md-3, .news_sec .col-md-12 {padding-left: 20px; padding-right: 20px;}
    table.dataTable#entrie_table thead tr th:first-child, table.dataTable#entrie_table thead tr td:first-child, table.dataTable#entrie_table tbody tr th:first-child, table.dataTable#entrie_table tbody tr td:first-child {padding-left: 20px;}
    table.dataTable#entrie_table thead tr th:last-child, table.dataTable#entrie_table thead tr td:last-child, table.dataTable#entrie_table tbody tr th:last-child, table.dataTable#entrie_table tbody tr td:last-child {padding-right: 20px;}
}

@media (min-width: 768px) and (max-width: 991px) {
    .news_sec .news-col {width: 40%;}
}
@media (max-width: 1299px) {
    .left_side ul li {padding: 0 15px;}
    .left_side ul li.li_subchild ul.sec_lev_menu {margin: 0 -15px;}
    .banner_content .row .col-xs-12.col-md-12 {z-index: 2;}
}
@media (max-width: 1199px) {
    .logo_div {width: 200px; }
    .header_top ul li a {padding: 11px 10px 9px 10px; font-size: 14px; }
    .heder_bottom ul li {margin-left: 15px;}
    .banner h1 {font-size: 40px;line-height: 50px; }
    .left_side {width: 20%;}
    .right_side {width: 80%;}
    table.dataTable#entrie_table thead th, table.dataTable#entrie_table thead td {font-size: 12px;}
    .banner_content .row .col-xs-12.col-md-12 {z-index: 2;}
}
@media (max-width: 991px) {
    h2 {font-size: 34px;line-height: 36px;}
    h4 {font-size: 18px;line-height: 24px; }
    .mobile_menu_btn {display: block;margin-top: 11px; margin-right: 25px; }
    .mobile_menu_btn span {width: 38px; height: 28px; display: block;}
    .mobile_menu_btn span::after {content: "";display: block;height: 0;box-shadow: 3px 3px 0 3px #ffffff, 3px 14px 0 3px #ffffff, 3px 25px 0 3px #ffffff;width: 32px;}
    .header_sec .header_top .top_left, .header_sec .header_top .top_right, .header_sec .heder_bottom .menu { display: none; }
    .header_top {height: 20px;}
    .header_sec .heder_bottom {height: 50px;}
    .logo_div {width: 168px;}
    .banner {height: 320px; }
    .banner h1 {font-size: 39px;line-height: 45px;}
    .banner p {font-size: 14px;line-height: 20px; }
    .banner_left_img {position: absolute;left: 0;z-index: 1;width: 319px; height: 298px;background: url(../img/tab_left_img.png) no-repeat;background-repeat: no-repeat;background-size: cover;}
    .banner_right_img {position: absolute; right: 0;z-index: 1; width: 345px; height: 315px; background: url(../img/tab_right_img.png) no-repeat;background-repeat: no-repeat;background-size: cover;}
    .d_button {padding: 5px 10px;font-size: 15px;line-height: 20px; }
    .bottom_img {margin-top: 15px;}
    .banner p {margin-bottom: 15px;}
    .g_border {margin: 0 auto 5px; }
    .banner .tableDiv {padding-top: 70px;}
    .mid_sec .right_content p {font-size: 12px;line-height: 20px;}
    .mid_sec {padding: 25px 0;}
    .news_sec {padding: 25px 0 1px;}
    .vider_div iframe {height: 250px;}
    .bottom_sec {padding: 30px 0;}
    .copyright_col {width: 25%;}
    .address_col {width: 36%; }
    .menu_col {width: 18%; }
    .inner_footer {padding: 30px 0;}
    .footer_social ul {float: none !important; }
    .footer_social ul li {float: none;margin-left: 10px;margin-bottom: 10px;}
    .news_sec .newsBox h4 {font-size: 24px; line-height: 26px; }
    .news_sec .news-col:nth-child(2n+1) { clear: both; }
    .blog_page .container { width: auto; }
    .right_sidebar {margin-left: -15px; margin-right: -15px;}
    .blog_page .custom_container {max-width: 720px; margin: auto;}
    .right_sidebar .news_letter {max-width: 220px;margin: auto;text-align: center;}
    .blog_page .page_title {margin-top: 20px; }
    .fullwidth-banner {height: 140px; }
    .left_side {display: none;}
    .right_side {width: 100%;}
    .bg {background: none;}
    .horse_bg img {display: none;}
    .horse_bg {position: relative;width: 100%;height: 80px; background: url('../img/tab_bg_img.jpg') no-repeat;background-size: cover;background-position: 50% 50%;}
    .white_bg {padding: 0 0 30px; }
    .page_title {margin-bottom: 25px;}
    a.green_btn {margin-right: 4px;}
    /*table.table_list tr td {padding: 12px 15px;}*/
    .entrie_div  {display: none;}
    .tablet_entrie {display: block;}
    .banner_content .row .col-xs-12.col-md-12 {z-index: 2;}
}

@media (max-width: 767px) {
    h2 {font-size: 25px;line-height: 30px;}
    .mid_sec .tableDiv {margin:  10px 0; }
    .table_cell.left_img {width: 100px;}
    .mid_sec .imgBox img {max-width: 100%;}
    /*.news_sec .newsBox h4 {min-height: 52px; }*/
    .news_sec .newsBox img {width: 100%; }
    .vider_div iframe {margin: 0 auto 20px;display: block;}
    .bottom_sec .cont_div {margin-top: 0px;max-width: 100%;float: none;}
    .copyright_col, .menu_col {margin-bottom: 30px;}
    .footer_social, .menu_col {float: left;width: 40%;}
    .copyright_col,.address_col {width: 60%;}
    .footer_social ul li {margin-left: 0;}
    .banner h1 {font-size: 30px;line-height: 36px;}
    .page_title .heading {font-size: 25px;line-height: 30px;background: url('../img/mob_title_bg.png') no-repeat 50% 100%;}
    .blog_page .page_title {margin-bottom: 20px;}
    .page_title .heading {padding: 0 0 20px 0;}
    .blogBox .col-xs-12.col-sm-4.col-md-4-1-1, .blogBox .blog_img { display: none;}
    .blogBox {padding-bottom: 20px;margin-bottom: 20px; }
    .blogBox .blog_text h4 {font-size: 20px;line-height: 24px;}
    ul.pogination li {margin: 0 3px 10px; }
    .fuego_page .col-xs-12.col-sm-7.col-md-7.pull-right img { display: block; margin: 0 auto 20px; }
    .tablet_entrie .row_box {margin-bottom: 0px !important;border-bottom: 0px solid #ae955e !important;}
    .tablet_entrie .colBox {margin-bottom: 30px;border-bottom: 1px solid #ae955e;}
    .tablet_entrie .row_box:last-child .col-xs-12:last-child .colBox {margin-bottom: 0px; border-bottom: 0px solid #ae955e; }
    .banner_content .row .col-xs-12.col-md-12 {z-index: 2;}
}

@media (max-width: 599px) {
    .banner h1 {font-size: 28px;line-height: 30px;}
    .banner p {font-size: 12px;line-height: 18px;margin-bottom: 10px;}
    .bottom_img {margin-top: 10px;}
    .banner_right_img {display: none; }
    .g_border span:before, .g_border span:after {width: 100px;}
    .banner {height: 300px;}
    .banner .tableDiv {padding-top: 40px;}
    .banner .d_button {padding: 3px 10px;font-size: 12px;line-height: 16px;}
    ul.pogination li.mob_hide {display: none;}
    .g_border {margin: 0 auto;}
    .close_btn {padding: 10px 0 20px 0;}
    .mobile_menu .nav li a {font-size: 18px;line-height: 22px;}
    .mobile_menu .social ul {margin: 20px 0 0;}
    .mobile_menu .social ul li a {padding: 5px 20px 5px 20px; }
    .mobile_menu .social ul li.active a {padding: 11px 20px 10px 20px;}
    .mobile_menu .nav .dropdown-menu {margin: 0 -19px 0 20px;}
    .mobile_menu .nav li a {padding: 4px 15px 5px 15px; }
    .mobile_menu .nav .dropdown-menu li a {padding: 7px 10px;}
    .close_btn a {margin-right: 20px;}
    .mobile_menu .nav li {margin: 0 19px;}
    .mobile_menu {right: -220px;width: 220px;}
    .close_btn a {display: block;width: 30px;height: 30px; }
    i.cross {width: 30px;height: 30px; }
    i.cross:after, i.cross:before {width: 34px;height: 4px;top: 13px;}
    /* off_canvas_nav */
    .mobile_menu.open_menu {
        opacity: 1;
        right: 0;
        -webkit-animation: slideIn 0.35s ease-in-out;
        -moz-animation: slideIn 0.35s ease-in-out;
        -o-animation: slideIn 0.35s ease-in-out;
        animation: slideIn 0.35s ease-in-out; }

    @-webkit-keyframes slideIn {
        0% {
            opacity: 1;
            right: -220px; }

        100% {
            opacity: 1;
            right: 0; } }

    @-moz-keyframes slideIn {
        0% {
            opacity: 1;
            right: -220px; }

        100% {
            opacity: 1;
            right: 0; } }

    @-o-keyframes slideIn {
        0% {
            opacity: 1;
            right: -220px; }

        100% {
            opacity: 1;
            right: 0; } }

    @keyframes slideIn {
        0% {
            opacity: 1;
            right: -220px; }

        100% {
            opacity: 1;
            right: 0; } }

    /*Nav Shrinking Closed Effect*/
    .mobile_menu.close_menu {
        opacity: 1;
        right: -220px;
        -webkit-animation: slideOut 0.35s ease-in-out;
        -moz-animation: slideOut 0.35s ease-in-out;
        -o-animation: slideOut 0.35s ease-in-out;
        animation: slideOut 0.35s ease-in-out; }

    @-webkit-keyframes slideOut {
        0% {
            opacity: 1;
            right: 0; }

        100% {
            opacity: 1;
            right: -220px; } }

    @-moz-keyframes slideOut {
        0% {
            opacity: 1;
            right: 0; }

        100% {
            opacity: 1;
            right: -220px; } }

    @-o-keyframes slideOut {
        0% {
            opacity: 1;
            right: 0; }

        100% {
            opacity: 1;
            right: -220px; } }

    @keyframes slideOut {
        0% {
            opacity: 1;
            right: 0; }

        100% {
            opacity: 1;
            right: -220px; } }
    .banner_content .row .col-xs-12.col-md-12 {z-index: 2;}
}
@media (max-width: 480px) {
    .table_cell.left_img {width: 75px;}
    .mid_sec .right_content {padding-left: 10px;}
    .mid_sec {padding: 15px 0;}
    .news_sec .newsTitle {padding-right: 0; }
    .news_sec .view_all_news {margin-top: 0px;position: relative;margin-bottom: 20px;}
    .news_sec .newsTitle h2 {margin-bottom: 15px;}
    .news_sec .news-col { width: 100%; }
    .news_sec .news-col { height: auto !important; }
    .logo_div {width: 118px;}
    .mobile_menu_btn {margin-top: 16px;width: 23px;height: 18px;}
    .mobile_menu_btn span::after {content: "";display: block;height: 0;box-shadow: 2px 2px 0 2px #ffffff, 2px 9px 0 2px #ffffff, 2px 16px 0 2px #ffffff;width: 19px;}
    .mobile_menu_btn span {width: 23px;height: 18px;}
    .banner_left_img {width: 300px;background-size: cover;}
    .img_border {border-bottom: 5px solid #ae955e;}
    a.green_btn {font-size: 14px;padding: 2px 10px;margin-bottom: 10px;}
    .left_box {margin-bottom: 20px; }
    .horse_bg {height: 50px; }
    .banner_content .row .col-xs-12.col-md-12 {z-index: 2;}
}


.left_menu li:hover > .menu-hover-item {display: inline;}
.menu-hover-item {display:none;position: absolute;left: 200px; z-index: 100; width: 150px; background: #000; color: white; padding: 5px; min-height: 55px; text-align: center;}


