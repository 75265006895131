@CHARSET "UTF-8"; /* CSS Document */
.element-invisible {
    display: none;
}

.client-editable {
    position: relative;
}

.client-editable:hover {
    outline: solid 1px #CCC;
}

.client-editable a.client-editable-link {
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    display: none;
    background: #CCC;
    padding: 5px 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    font-weight: normal;
    color: #b8132a;
    text-decoration: underline;
}

.client-editable:hover a.client-editable-link {
    display: block;
}

input[type="submit"] {
    padding: 6px 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 3px 5px 0 2px;
    background: #af965a;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    -webkit-transition: all 600ms ease-out;
    -moz-transition: all 600ms ease-out;
    -o-transition: all 600ms ease-out;
    transition: all 600ms ease-out;
}

input[type="submit"]:hover {
    background: #967f4a;
}

input[type="text"], input[type="password"] {
    border-radius: 3px;
    border: 1px solid #af965a;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    padding: 4px 10px 3px;
    margin-bottom: 20px;
}

textarea {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #af965a;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    padding: 4px 10px 3px;
    margin-bottom: 20px;
}

textarea:focus {
    border: 1px solid #af965a;
}

.form-errors ul li {
    color: red;
}

.main-content .form-errors ul {
    padding-left: 17px;
}

/* webform */
.webform-client-form .form-item {
    float: left;
    width: 45%;
    margin-right: 30px;
}

.webform-client-form .form-item.webform-component-textarea {
    clear: both;
    width: 92.7%;
}

.webform-client-form input[type="text"], .webform-client-form input[type="email"], .webform-client-form textarea, .webform-client-form select {
    width: 100%;
    border-radius: 3px;
    border: 1px solid #af965a;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    padding: 4px 10px 3px;
    margin-bottom: 20px;
}

.webform-client-form .form-actions {
    clear: both;
}

.webform-client-form .form-actions input[type="submit"] {
    padding: 6px 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 3px 0 0 2px;
    background: #af965a;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    -webkit-transition: all 600ms ease-out;
    -moz-transition: all 600ms ease-out;
    -o-transition: all 600ms ease-out;
    transition: all 600ms ease-out;
}

.webform-client-form .form-actions input[type="submit"]:hover {
    background: #967f4a;
}

.webform-client-form .webform-component--receive-updates {
    width: 100%;
}

.webform-client-form .webform-component--receive-updates label {
    font-weight: normal;
    font-size: 12px;
}

.prev_arrow {
    margin: 5px auto;
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #ffffff;
}

.photos-div ul {
    list-style: none;
}

.photos-div ul li {
    float: left;
    padding-right: 20px;
    margin-bottom: 18px;
}

.photos-div ul li img {
    border-bottom: 10px solid #ae955e;
    width: 425px;
}

.videos-div ul {
    list-style: none;
}

.videos-div ul li {
    float: left;
    padding-right: 20px;
    margin-bottom: 18px;
}

/* table css */
table.dataTable#stats_table {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;
}

table.dataTable#stats_table.no-footer {
    border-bottom: 0px solid #111111;
}

table.dataTable#stats_table thead th, table.dataTable#stats_table thead td, #results_table thead th, #results_table thead td {
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    min-width: 115px;
    padding: 5px 10px;
    border-bottom: 0px solid #111111;
    background-color: #08563c;
    border-bottom: 5px solid #ffffff;
}

#results_table td span, #results_table td a, #results_table td  {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    /*font-weight: 600;*/
}

#results_table {
    visibility: hidden;
}

#results_table .views-exposed-widgets {
   display: flex;
   align-items: center;
}

#results_table .views-exposed-widgets input {
   height: 35px;
}

#results_table .views-exposed-widgets input[type="submit"] {
   position: relative;
   top: 1px;
}

#results_table table{
   width: 100%;
}

#results_table thead th a {
   text-align: center;
   font-weight: 600;
   text-transform: uppercase;
   color: white;
   position: relative;
}

#results_table thead th a img {
   position: absolute;
   right: -100%;
   top: 5%;
}

#results_table .current a{
       background-position-x: 100%;
}

#results_table .cardtable.stacktable.small-only {
   display: none;
}

@media (max-width: 991px) {
    #results_table .stacktable.large-only{
      display: none;
    } 

   #results_table .cardtable.stacktable.small-only {
      display: table;
      width: 100%;
   }

   #results_table .cardtable.stacktable.small-only table{
        width: 48%;
        display: inline-table;
        margin: 0% 1% 1% 1%;
   }

   #results_table .cardtable.stacktable.small-only table .st-key {
        text-transform: uppercase;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 600;
   }

   #results_table .cardtable.stacktable.small-only table .st-val {
        font-weight: bold;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 600;
   }

   #results_table .cardtable.stacktable.small-only  .small-hr {
      display: none;
   }

   #results_table .cardtable.stacktable.small-only hr {
        border-bottom: 1px solid #ae955e;
        margin: 5% 0;
   }

   #results_table .cardtable.stacktable.small-only hr:nth-of-type(2n+1) {
      display: none;
   }

   #results_table .cardtable.stacktable.small-only hr:last-of-type {
        border: none;
        margin: 2%;
   }

   #results_table .cardtable.stacktable.small-only table a {
    position: relative;
   }

    #results_table .cardtable.stacktable.small-only table a img {
       position: absolute;
       right: -100%;
       top: 10%;
    }

   #results_table .cardtable.stacktable.small-only table .title_text {
        padding-left: 15px;
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 20px;
        color: #ae955e;
        font-family: 'Source Sans Pro', sans-serif;
    }

/*   #results_table thead th, #results_table thead td {
      width: 100%;
   }*/

}

@media (max-width: 639px) { 
   #results_table .cardtable.stacktable.small-only  hr:nth-of-type(1n + 1) {
      display: block ;
   }


   #results_table .cardtable.stacktable.small-only table{
        width: 100%;
        display: inline-table;
        /*margin: 0% 1% 1% 1%;*/
   }
}

#stats_table_wrapper .dataTables_info{
    display: none;
}

table.dataTable#stats_table.stripe tbody tr.odd, table.dataTable#stats_table.display tbody tr.odd, table.dataTable#stats_table.display tbody tr.odd > .sorting_1, table.dataTable#stats_table.order-column.stripe tbody tr.odd > .sorting_1 {
    background-color: #e3e3e3;
}

table.dataTable#stats_table tbody th, table.dataTable#stats_table tbody td, #results_table tbody th, #results_table tbody td {
    padding: 15px 10px;
    text-align: center;
}

table.dataTable#stats_table tbody th a, table.dataTable#stats_table tbody td a, #results_table tbody th a, #results_table tbody td a {
    color: #0e764a;
}

table.dataTable#stats_table.display tbody tr.even > .sorting_1, table.dataTable#stats_table.order-column.stripe tbody tr.even > .sorting_1 {
    background-color: #ffffff;
}

table.dataTable#stats_table tbody tr.even:hover, table.dataTable#stats_table.display tbody tr.even:hover {
    background-color: transparent;
}

@media (min-width: 1300px) {
    table.dataTable#stats_table thead tr th:first-child, table.dataTable#stats_table thead tr td:first-child, table.dataTable#stats_table tbody tr th:first-child, table.dataTable#stats_table tbody tr td:first-child {
        padding-left: 20px;
    }

    table.dataTable#stats_table thead tr th:last-child, table.dataTable#stats_table thead tr td:last-child, table.dataTable#stats_table tbody tr th:last-child, table.dataTable#stats_table tbody tr td:last-child {
        padding-right: 20px;
    }
}

@media (max-width: 1199px) {
    table.dataTable#stats_table thead th, table.dataTable#stats_table thead td {
        font-size: 12px;
    }
}

.contentDiv.bg .white_bg {
    min-height: 627px;
}

.press-entry {
    margin-bottom: 10px;
}

.press-entry a {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    color: #0e764a;
}

.view .date-nav-wrapper .date-prev {
    right: 80px;
}

.mobile_menu .nav .dropdown-menu li > .dropdown-menu li:last-child {
    border-bottom: none;
}

#comments > h2.title {
    border-top: 5px solid #eeeeee;
    padding-top: 20px;
    margin-top: 20px;
}

.comment {
    margin-bottom: 10px;
}

.comment .submitted {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}

.comment .submitted .username {
    color: #000000;
}

.comment .content {
    margin: 20px 0px 10px 20px;
    background-color: #eeeeee;
    padding: 20px 20px 5px 20px;
}

.comment > h3 > a {
    color: #af955e;
}

.comment ul.links {
    margin-left: -20px;
}

.comment ul.links li {
    list-style: none;
    display: inline-block;
}

.comment ul.links li a {
    font-weight: 700;
    background: #aaaaaa;
    padding: 5px 10px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}

.comment-wrapper form {
    margin-bottom: 50px;
}

.comment-form .form-item-homepage {
    display: none;
}

.comment-form .form-item input, .comment-form .form-item textarea {
    margin-bottom: 0px;
}

.comment-form .form-item {
    margin-bottom: 20px;
}

.comment-form .field-name-comment-body .filter-wrapper {
    display: none;
}

.comment-form .form-actions {
    margin-top: 20px;
}

.sharethis-buttons {
    text-align: right;
}

.header_top ul li .section-search {
    padding-left: 10px;
}

.header_top ul li .section-search .form-item-keys {
    display: inline-block;
}

.header_top ul li .section-search .form-item-keys input {
    line-height: 20px;
    width: 180px;
}

.header_top ul li .section-search .form-item-keys label {
    display: none;
}

.main-content .search-form .form-item-keys {
    display: inline-block;
}

.main-content .search-form .form-item-keys input {
    line-height: 20px;
}

.main-content ol.search-results {
    list-style: none;
    margin-left: -40px;
}

.main-content ol.search-results h3 a {
    color: #af955e;
    font-size: 25px;
}

.blog-comment-message {
    color: red;
    margin-top: 20px;
}

.captcha {
    display: block;
    width: 100%;
    margin: 20px 0;
}

.captcha legend, .captcha .fieldset-description {
    display: none;
}

.search-form {
    padding-left: 0;
}

.search-form .form-text {
    margin-bottom: 0;
}

.header_top ul li .section-search .form-item-keys input {
    width: 120px;
}

@media (max-width: 1199px) {
    .header_top ul li .section-search .form-item-keys input {
        display:none;
    }
}
